import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import 'dayjs/locale/ka';

// material ui 
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// for styled
import styled from "styled-components";
import Responsive from "config/Responsive";
import Fonts from "styles/Fonts";
import Colors from "styles/Colors";
import '../../components/header.css'; 

// icons 
import ButtonFilter from '../../icons/ButtonFilter';
import PlusIcon from '../../icons/PlusIcon';

import DateInput from "../../components/Input/DateInput";

// redux
import { useSelector } from "react-redux";
import { getPolicies, getClaims, getClaimHistory } from "../../slices/insuranceSlice";
import { RootState } from "../../slices";
import { InsuranceState } from "../../domain";
import { useAppDispatch } from "../../config/store";
import PropTypes from "prop-types";
import Loader from "components/Loader";
import NotFound from "components/InformationNotFound";




// style
const ClaimListContainer = styled.div`
  border-radius: 10px;
  height: 100%;
      ${Responsive.laptop}{
    
      }
      ${Responsive.tablet}{
     
      }
      ${Responsive.mobile}{
        padding-bottom: 60px;
      }
`;
const ClaimListTabsStyled = styled(Tabs)`
  background-color: #ffffff !important;
  height: 120px !important;
  opacity: unset !important;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${Responsive.laptop} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.tablet} {
    height: 85px !important;
    min-height: 85px !important;
  }
  ${Responsive.mobile} {
    height: 75px !important;
    min-height: 75px !important;
    height: 280px !important;
    position: relative;
  }
`;
const ClaimListTabStyle = styled(Tab)`
  font-size: 18px !important;
  outline: none !important;
  color: ${Colors.Primary} !important;
  max-width: 200px !important;
  width: 100% !important;
  font-family: ${Fonts.FiraGOMtavruliRegular} !important;
  font-weight: 400 !important;
  margin-top: 38px !important;
  opacity: unset !important;
  ${Responsive.laptop} {
    font-size: 13px !important;
    max-width: 140px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.tablet} {
    font-size: 13px !important;
    max-width: 115px !important;
    min-width: 100px !important;
    margin-top: unset !important;
    height: 85px !important;
  }
  ${Responsive.mobile} {
    font-size: 13px !important;
    max-width: 50% !important;
    margin-top: unset !important;
    height: 75px !important;
    padding: unset !important;
    line-height: 15px !important;
    &:focus {
      width: 100% !important;
    }
  }
`;
const ClaimListHeadDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 35px;
  margin-right: 20px;
    ${Responsive.laptop}{
      margin-top: unset;
    }
    ${Responsive.tablet}{
      margin-top: unset;
      margin-right: 10px;
    }
    ${Responsive.mobile}{
      position: absolute;
      top: 80px;
      justify-content: center;
      flex-wrap: wrap;
    }
`;
const ClaimListSelect = styled.select`
    height: 50px;
    width: 35%;
    border-radius: 6px;
    border: 1px solid #E5E6ED;
    color: #636381;
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    outline: none;
    padding-left: 20px;
        ${Responsive.laptop}{
            height: 42px;
            width: 35%;
            font-size: 13px;
            border-radius: 4px;
            padding-left: 12px;
        }
        ${Responsive.tablet}{
            height: 42px;
            width: 37%;
            font-size: 13px;
            border-radius: 4px;
            padding-left: 12px;
        }
        ${Responsive.mobile}{
          height: 38px;
          width: 80%;
          font-size: 12px;
          border-radius: 4px;
          padding-left: 15px;
      }
`;
const ClaimListNewLink = styled(Link)`
    width: 35%;
    margin-left: 20px;
    margin-right: 20px;
      ${Responsive.laptop}{
        margin-left: 15px;
        margin-right: 15px;
        width: 35%;
      }
      ${Responsive.tablet}{
        margin-left: 10px;
        margin-right: 10px;
        width: 37%;
      }
      ${Responsive.mobile}{
        width: 60%;
        margin-top: 20px;
      }
`;
const ClaimListNewButton = styled.button`
    height: 50px;
    width: 100%;
    border-radius: 6px;
    border: unset;
    background-color: #261e56;
    color: #fff;
    outline: none;
    font-size: 13px;
    font-family: ${Fonts.FiraGOMtavruliBold};
   
        &:focus,
        &:hover{
            outline: none;
            background-color: ${Colors.HoverRed};
        }
    ${Responsive.laptop}{
        height: 42px;
        /* width: 35%; */
        border-radius: 4px;
        font-size: 12px;
        /* margin-left: 15px;
        margin-right: 15px; */
    }
    ${Responsive.tablet}{
        height: 42px;
        /* width: 37%; */
        border-radius: 4px;
        font-size: 12px;
        /* margin-left: 10px;
        margin-right: 10px; */
    }
    ${Responsive.mobile}{
      height: 38px;
      /* width: 60%; */
      border-radius: 4px;
      font-size: 12px;
      margin: unset;
      /* margin-top: 20px; */
  }
`;
const ClaimListHideButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 6px;
  outline: none;
  border: unset;
  margin-left: unset;
  background-color: #ebe8fc;
  display: flex;
  justify-content: center;
  align-items: center;
    &:focus{
      outline: none;
    }
  ${Responsive.laptop}{
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
  ${Responsive.tablet}{
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
  ${Responsive.mobile}{
    height: 38px;
    width: 38px;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 20px;
  }
`;
const ClaimListPlusIcon = styled(PlusIcon)`
  height: 10px;
  width: 10px;
  color: #fff;
  margin-top: 2px;
  margin-right: 10px;
    ${Responsive.laptop}{
      margin-top: 1.3px;
    }
    ${Responsive.tablet}{
      margin-top: 1.3px;
    }
`;
const ClaimListHideIcon = styled(ButtonFilter)`
  height: 16px;
    ${Responsive.laptop}{
      height: 11px;
    }
    ${Responsive.tablet}{
      height: 11px;
    }
    ${Responsive.mobile}{
      height: 11px;
    }
`;
const ClaimListSelectContainer = styled.div`
/*  */
`;
const ClaimListTopSelectDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 50px;
    ${Responsive.laptop}{
      margin-top: 35px;
    }
    ${Responsive.tablet}{
      margin-top: 35px;
    }
    ${Responsive.mobile}{
      margin-top: unset;
      flex-direction: column;
    }
`;
const ClaimListBottomSelectDiv = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
    ${Responsive.mobile}{
      flex-direction: column;
      align-items: center;
      margin-top: unset;
      margin-bottom: 20px;
    }
`;
const ClaimListInput = styled.input`
  height: 50px;
  border-radius: 6px;
  border: 1px solid #E5E6ED;
  color: ${Colors.Input};
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  outline: none;
  padding-left: 20px;
  width: 30%;
    &::placeholder {
      color: ${Colors.Input};
    }
    &:focus
    &:hover{
      outline: none;

    }
  ${Responsive.laptop}{
    height: 42px;
    width: 30%;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 15px;
  }
  ${Responsive.tablet}{
    height: 42px;
    width: 30%;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 15px;
  }
  ${Responsive.mobile}{
    height: 38px;
    width: 80%;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 15px;
  }
`;
const ClaimListInputBottom = styled.input`
  height: 50px;
  border-radius: 6px;
  border: 1px solid #E5E6ED;
  color: ${Colors.Input};
  font-size: 16px;
  font-family: ${Fonts.FiraGORegular};
  font-weight: 400;
  outline: none;
  padding-left: 20px;
  width: 21%;
    &::placeholder {
      color: ${Colors.Input};
    }
    &:focus
    &:hover{
      outline: none;

    }
  ${Responsive.laptop}{
    height: 42px;
    width: 21%;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 15px;
  }
  ${Responsive.tablet}{
    height: 42px;
    width: 21%;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 15px;
  }
  ${Responsive.mobile}{
    height: 38px;
    width: 80%;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 15px;
    margin-top: 15px;
  }
`;
const ClaimListDateSelect = styled.select`
    height: 50px;
    width: 30%;
    border-radius: 6px;
    border: 1px solid #E5E6ED;
    color: ${Colors.Input};
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    outline: none;
    padding-left: 20px;
        ${Responsive.laptop}{
            height: 42px;
            width: 30%;
            font-size: 13px;
            border-radius: 4px;
            padding-left: 15px;
        }
        ${Responsive.tablet}{
            height: 42px;
            width: 30%;
            font-size: 13px;
            border-radius: 4px;
            padding-left: 15px;
        }
        ${Responsive.mobile}{
          height: 38px;
          width: 80%;
          font-size: 12px;
          border-radius: 4px;
          padding-left: 15px;
          margin-top: 15px;
      }
`;
const ClaimListDateSelectBottom = styled.select`
    height: 50px;
    width: 21%;
    border-radius: 6px;
    border: 1px solid #E5E6ED;
    color: ${Colors.Input};
    font-size: 16px;
    font-family: ${Fonts.FiraGORegular};
    outline: none;
    padding-left: 20px;
        ${Responsive.laptop}{
            height: 42px;
            width: 21%;
            font-size: 13px;
            border-radius: 4px;
            padding-left: 15px;
        }
        ${Responsive.tablet}{
            height: 42px;
            width: 21%;
            font-size: 13px;
            border-radius: 4px;
            padding-left: 15px;
        }
        ${Responsive.mobile}{
          height: 38px;
          width: 80%;
          font-size: 12px;
          border-radius: 4px;
          padding-left: 15px;
          margin-top: 15px;
      }
`;
const ClaimListFilterButton = styled.button`
    height: 50px;
    width: 23%;
    border: 1px solid ${Colors.Primary};
    border-radius: 6px;
    outline: none;
    color: ${Colors.Primary};
    background-color: #fff;

    font-family: ${Fonts.FiraGOMtavruliRegular};
      &:focus,
      &:hover{
        outline: none;
        color: #fff;
        background-color: ${Colors.Primary};
        border-color: ${Colors.Primary};
      }
    ${Responsive.laptop}{
      height: 42px;
      width: 23%;
      border-radius: 4px;
      font-size: 16px;
    }
    ${Responsive.tablet}{
      height: 42px;
      width: 23%;
      border-radius: 4px;
      font-size: 13px;
    }
    ${Responsive.mobile}{
      height: 38px;
      width: 80%;
      border-radius: 4px;
      font-size: 12px;
      margin-top: 15px;
    }
`;
const TableHeader = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    user-select: none;
    pointer-events: none;
    margin-top: 30px;
      &:hover,
      &:focus {
        background-color: inherit;
      }
      ${Responsive.mobile}{
        display: none;
      }
`;
const TableRow = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    transition: all 100ms;
    &:hover,
      &:focus {
        background-color: #ebe8fc;
        transition: all 100ms;
      }
      ${Responsive.mobile}{
        position: relative;
        /* margin-top: 30px; */
        border-bottom: 1px dotted #E5E6ED;
        height: 100px;
        /* background-color: green; */
      }
`;
const TableHeaderColumn = styled.div`
   font-size: 13px;
   font-family: ${Fonts.FiraGORegular};
   font-weight: 400;
   color: #767880;
   margin: unset; 
   display: block;
   line-height: 50px;
        &:nth-child(1){
          width: 10%;
          text-align: center;
          /* background-color: green; */
        }
        &:nth-child(2){
          width: 20%;
          text-align: left;
          /* background-color: aqua; */
        }
        &:nth-child(3){
          width: 20%;
          text-align: left;
          padding-left: 20px;
          /* background-color: yellow; */
        }
        &:nth-child(4){
          width: 20%;
          text-align: left;
          padding-left: 20px;
          /* background-color: pink; */
        }
        &:nth-child(5){
          width: 20%;
          text-align: left;
          padding-left: 20px;
          /* background-color: pink; */
        }
        &:nth-child(6){
          width: 15%;
          text-align: right;
          padding-right: 30px;
          /* background-color: greenyellow; */
        }

    ${Responsive.laptop}{
      font-size: 12px;
      line-height: 50px;
    }
    ${Responsive.tablet}{
      font-size: 12px;
      line-height: 50px;
    }
    ${Responsive.mobile}{
      display: none;
    }
`;
const TableRowColumn = styled.div`
   font-size: 13px;
   font-family: ${Fonts.FiraGORegular};
   font-weight: 400;
   color: #767880;
   margin: unset; 
   display: block;
   line-height: 50px;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
        &:nth-child(1){
          width: 10%;
          text-align: center;
          /* background-color: green;   */
            ${Responsive.mobile}{
              display: none;
            }
        }
        &:nth-child(2){
          width: 20%;
          text-align: left;
          /* background-color: aqua; */
            ${Responsive.mobile}{
              width: 60%;
              position: absolute;
              top: 0;
              left: 0;
            }
        }
        &:nth-child(3){
          width: 20%;
          text-align: left;
          padding-left: 20px;
          /* background-color: yellow; */
            ${Responsive.mobile}{
              position: absolute;
              top: 50px;
              left: 0;
              text-align: left;
              padding: unset;
              width: 60%;
            }
        }
        &:nth-child(4){
          width: 20%;
          text-align: left;
          padding-left: 20px;
          /* background-color: pink; */
            ${Responsive.mobile}{
              position: absolute;
              top: 50px;
              right: 0;
              text-align: right;
              padding: unset;
              width: 40%;
            }
        }
        &:nth-child(5){
          width: 20%;
          text-align: left;
          padding-left: 20px;
          /* background-color: pink; */
            ${Responsive.mobile}{
              position: absolute;
              top: 50px;
              right: 0;
              text-align: right;
              padding: unset;
              width: 40%;
            }
        }
        &:nth-child(6){
          width: 15%;
          text-align: right;
          padding-right: 30px;
          /* background-color: greenyellow; */
            ${Responsive.mobile}{
              position: absolute;
              top: 0;
              right: 0;
              text-align: right;
              padding: unset;
              width: 40%;
            }
        }

    ${Responsive.laptop}{
      font-size: 12px;
      line-height: 50px;
    }
    ${Responsive.tablet}{
      font-size: 12px;
      line-height: 50px;
    }
    ${Responsive.mobile}{
      /* display: none; */
    }
`;
const ClaimLinkFocus = styled(Link)`
  text-decoration: none;
    &:hover {
      text-decoration: none;
    }
`;











interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: {
  [x: string]: any;
  children: any;
  value: any;
  index: any;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
 





export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);


  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const options = ["Option 1", "Option 2"];
  const [show, setShow] = useState(true);
  const [selectedPolid, setSelectedPolid] = useState("");
  //
  const {
    policies,
    // policiesLoading,
    activeMedicalPolicy,
    claims: claimsUnsorted,
    claimsLoading,
    claimHistory: claimHistoryUnsorted = [],
    // claimHistoryLoading,
  } = useSelector<RootState, InsuranceState>((data) => data.insurance);
  // sort claims DESC
  const claims = claimsUnsorted
      .slice()
      .sort((a, b) => (dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : a == b ? 0 : 1));
  const claimHistory = claimHistoryUnsorted
      .slice()
      .sort((a, b) => (dayjs(a.validFrom) > dayjs(b.validFrom) ? -1 : a == b ? 0 : 1));
  //
  const dispatch = useAppDispatch();
  //
  useEffect(() => {
    dispatch(getPolicies(null));
    dispatch(getClaims(null));
  }, []);
  useEffect(() => {
    activeMedicalPolicy && dispatch(getClaimHistory({ policyPolid: activeMedicalPolicy.policyPolid }));
  }, [activeMedicalPolicy]);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const filteredCurrentItems = claims.filter(claim => {
    const policy = policies.find(p => p.policyNumber == claim.policyNumber && p.policyPolid == claim.policyPolid);
    // filter by title
    if(filterSearch){
      if(claim.title.indexOf(filterSearch) === -1 && policy?.objectName.indexOf(filterSearch) === -1){
        return false;
      }
    }
    // filter by status
    if(filterStatus){
      if(claim.status !== filterStatus){
        return false;
      }
    }
    // filter by date
    // TODO
    return true;
  });

  return (
    <ClaimListContainer className={classes.root}>
    
      <AppBar position="static">
        <ClaimListTabsStyled
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <ClaimListTabStyle label="მიმდინარე" {...a11yProps(0)} />
          <ClaimListTabStyle label="დასრულებული" {...a11yProps(1)} />

          <ClaimListHeadDiv>

            <ClaimListSelect name="remuneration-list" id="remuneration-list"
                 onChange={(event) => {
                  setSelectedPolid(event.currentTarget.value);
                  event.stopPropagation();
                }}
            >
                  {policies.map((p) => (
                  <option key={p.policyPolid} value={p.policyPolid}>
                    {p.objectName || p.cardNumber}
                  </option>
                ))}

            </ClaimListSelect>


           <ClaimListNewLink  to="/claim/add">
             <ClaimListNewButton> <ClaimListPlusIcon /> ახალი საგარანტიო</ClaimListNewButton>
            </ClaimListNewLink>
            
            <ClaimListHideButton onClick={() => setShow(!show)}> <ClaimListHideIcon /> </ClaimListHideButton>
          </ClaimListHeadDiv>

        </ClaimListTabsStyled>
      </AppBar>
      
      {show ?  
      <ClaimListSelectContainer>
        <ClaimListTopSelectDiv>
        <ClaimListInput type="text" placeholder="დასახელება" id='remuneration-name' value={filterSearch} onChange={e => setFilterSearch(e.target.value)} />
        
        <span>
        <DateInput value={startDate} onChange={value => setStartDate(value)} />
        </span>
        {/* <ClaimListDateSelect name="" id="remuneration">
            <option selected value="">თარიღი-დან</option>
            <option value="Remuneration-two">2020-06-26</option>
            </ClaimListDateSelect> */}

        <span>
        <DateInput value={endDate} onChange={value => setEndDate(value)} />
       </span>
       {/* <ClaimListDateSelect name="" id="remuneration-to-date">
            <option disabled selected value="">თარიღი-მდე</option>
            <option value="Remuneration-to-date-one">2021-06-26</option>
            </ClaimListDateSelect> */}
        </ClaimListTopSelectDiv>

        <ClaimListBottomSelectDiv>
          <ClaimListInputBottom  type="text" placeholder="თანხა-დან" />
          <ClaimListInputBottom type="text" placeholder="თანხა-მდე" />
          <ClaimListDateSelectBottom name="" id="remuneration-status" onChange={e => setFilterStatus(e.target.value)}>
              <option selected value="">სტატუსი</option>
              <option value="request">მიმდინარე</option>
              <option value="approved">დასრულებული</option>
              <option value="rejected">გაუქმებული</option>
          </ClaimListDateSelectBottom>
        <ClaimListFilterButton>ფილტრი</ClaimListFilterButton>
        {/* <ClaimListFilterButton>ფილტრი</ClaimListFilterButton> */}
       {/* <ClaimListFilterButton onClick={() => clearFilters()}>გასუფთავება</ClaimListFilterButton> */}
        </ClaimListBottomSelectDiv>
      </ClaimListSelectContainer>
      : null}
      {claimsLoading ? (
            <Loader />
          ) :(
      <TabPanel value={value} index={0}>
       {filteredCurrentItems.length == 0 ? ( <NotFound /> ) : ( 
         <div>
        <TableHeader>
              <TableHeaderColumn>N</TableHeaderColumn>
              <TableHeaderColumn>დასახელება</TableHeaderColumn>
              <TableHeaderColumn>თარიღი</TableHeaderColumn>
              <TableHeaderColumn>პოლისის ნომერი</TableHeaderColumn>
              <TableHeaderColumn></TableHeaderColumn>
              <TableHeaderColumn>სტატუსი</TableHeaderColumn>
        </TableHeader>


     
      {filteredCurrentItems.map(claim => {
        const policy = policies.find(p => p.policyNumber == claim.policyNumber && p.policyPolid == claim.policyPolid);
        return (
          <ClaimLinkFocus to={`/claim/details/${claim.id}`}>
              <TableRow>
              <TableRowColumn>{claim.id.toString()}</TableRowColumn>  
              <TableRowColumn>{policy?.objectName || claim.title || 'არ არის მითითებული'}</TableRowColumn>
              <TableRowColumn>{dayjs(claim.createdAt).locale('ka').format('DD MMM YYYY | HH:mm')}</TableRowColumn>
              <TableRowColumn>{policy?.cardNumber || `${claim.policyNumber} ${claim.policyPolid}`}</TableRowColumn>
              <TableRowColumn></TableRowColumn>
              <TableRowColumn>{claim.status.replace('request', 'მოლოდინში')}</TableRowColumn>
            </TableRow>
          </ClaimLinkFocus>
      );
})}
      </div>
     )}
      </TabPanel>
  )} {/* loader */}
      <TabPanel value={value} index={1}>
      {claimHistory.length == 0 ? ( <NotFound /> ) : ( 
        <div>
        <TableHeader>
              <TableHeaderColumn>N</TableHeaderColumn>
              <TableHeaderColumn>დასახელება</TableHeaderColumn>
              <TableHeaderColumn>თარიღი</TableHeaderColumn>
              <TableHeaderColumn>თანხა</TableHeaderColumn>
              <TableHeaderColumn>გადახდილი</TableHeaderColumn>
              <TableHeaderColumn>სტატუსი</TableHeaderColumn>
        </TableHeader>
        {/* answer */}
        {claimHistory.map(claimHistoryItem =>  
          <ClaimLinkFocus to={`/claim/history/details/${claimHistoryItem.documentNumber}`}>
        <TableRow>
          <TableRowColumn>{claimHistoryItem.documentNumber}</TableRowColumn>  
          <TableRowColumn>{claimHistoryItem.serviceType} {claimHistoryItem.serviceName}</TableRowColumn>
          <TableRowColumn>{dayjs(claimHistoryItem.validFrom).locale('ka').format('DD MMM YYYY')}</TableRowColumn>
          <TableRowColumn>{claimHistoryItem.amount} ₾</TableRowColumn>
          <TableRowColumn>{Math.round(claimHistoryItem.amount * claimHistoryItem.outOfPocketPercent / 100)} ₾</TableRowColumn>
          <TableRowColumn>{claimHistoryItem.statusText}</TableRowColumn>
          </TableRow>
          </ClaimLinkFocus>
      
        )}
        </div>
           )}
      </TabPanel>
    </ClaimListContainer>
  );
}
