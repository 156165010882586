import React, { CSSProperties } from "react";
import styled from "styled-components";

import dayjs from 'dayjs';

const DateInputStyled = styled.input`
    height: 42px;
    border-radius: 4px;
    font-size: 14px;
    padding-left: 20px;
    border: 1px solid #E5E6ED;
    color: #aaaabe;
`;

type Props = {
  value: Date | undefined;
  onChange: (_: Date | undefined) => void;
  style?: CSSProperties;
};
const DateInput: React.FC<Props> = ({ value, onChange, style }: Props) => {
  return (
    <>
    <DateInputStyled
    type="date"
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
    value={value ? dayjs(value).format('YYYY-MM-DD') : undefined}
    onChange={event => onChange(event?.target.value ? dayjs(event?.target.value).toDate() : undefined)}
    style={style}
      />-მდე
    </>
  );
};
export default DateInput;
